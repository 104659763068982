import React, { Fragment } from "react";
import Layout from "../../components/layout/layout";
import { TextPhotoSection } from "../../components/TextPhotoSection/TextPhotoSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import { graphql, useStaticQuery } from "gatsby";
import Fold from "../../components/Fold/Fold";
import FadeDown from "../../components/FadeDown/FadeDown";
import { ItemsHolder } from "../../components/ItemsHolder/ItemsHolder";
import { IntroSection } from "../../components/IntroSection/IntroSection";
import { OrderedItem } from "../../components/OrderedItem/OrderedItem";

const IMAGE_QUERY = graphql`
  {
    aiImage: file(relativePath: { eq: "about-us/ai-power.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      publicURL
    }
    responsibilityImage: file(
      relativePath: { eq: "about-us/kickstage-responsibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

const OurWork = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="Kickstage AI Manifesto - Embracing Innovation with Responsibilty"
      description="At Kickstage, we harness the power of AI to drive innovation, efficiency, and growth, guided by responsibility and acommitment to delivering real value. Our approach to AI is defined by the following principles."
      image={tempImage.aiImage.publicURL} 
    >
      <FadeDown fold>
        <Fold
          inner
          title={
            <Fragment>
              <span>Kickstage AI Manifesto</span> - Embracing Innovation with
              Responsibilty
            </Fragment>
          }
          description="At Kickstage, we harness the power of AI to drive innovation, efficiency, and growth, guided by responsibility and acommitment to delivering real value. Our approach to AI is defined by the following principles."
        />
        <IntroSection image={tempImage.aiImage} />
      </FadeDown>

      <ItemsHolder twoColumn>
        <OrderedItem
          order="1."
          title="Continuous Learning and Innovation"
          description="We foster a culture of continuous learning and innovation. Our 4-day work week provides time for research and experimentation, we sponsor conference attendance, maintain university connections, recruit fresh talent, and embrace new technologies in client projects. This commitment ensures we stay at the forefront of AI and technological advancements."
        />
        <OrderedItem
          order="2."
          title="Adapting to the Changing Landscape"
          description="We anticipate and prepare for the transformative impact of AI on the tech industry. Recognizing the potential changes in entry-level programming roles, we maintain small, efficient teams and take responsibility for our employees' professional growth. We hire junior talent judiciously, providing effective training to future-proof their careers."
        />
        <OrderedItem
          order="3."
          title="Balancing Automation and Human Skills"
          description="We leverage AI to enhance our capabilities, not replace human ingenuity. By automating simple tasks, we free up time for complex problem-solving and creativity. We place equal emphasis on developing soft skills alongside technical proficiency, recognizing their growing importance in an AI- driven world."
        />
        <OrderedItem
          order="4."
          title="Maintaining Quality in AI-Assisted Development"
          description="We harness AI to accelerate product development without compromising quality. All AI-assisted work undergoes thorough reviews by senior developers, and we implement rigorous manual and automated QA processes to ensure product excellence and code maintainability."
        />
        <OrderedItem
          order="5."
          title="Enhancing Communication, Not Verbosity"
          description="Our use of AI in communication aims to refine and sharpen our messaging. We strive for concise, impactful communication, avoiding unnecessary verbosity. AI helps us distill complex ideas into clear, actionable insights."
        />
        <OrderedItem
          order="6."
          title="Sharing the Benefits of AI"
          description="We believe in passing the advantages of AI directly to our clients. The efficienciesgained through AI are reflected in improved output and value for our clients, not in increased margins for us. Our commitment is to use AI to enhance the quality and efficiency of our work, benefiting our clients directly."
        />
        <OrderedItem
          order="7."
          title="Respecting Intellectual Property"
          description="We use AI responsibly and ethically, ensuring that our clients retain full copyright to their code, even when AI tools are used in the development process. We are transparent about our use of AI and maintain clear boundaries to protect our clients' intellectual property rights."
        />
        <OrderedItem
          order="8."
          title="Pragmatic Approach to AI"
          description="We maintain a no-nonsense attitude towards AI, rejecting hype and empty promises. We recommend AI solutions to clients only when we are deeply convinced of their tangible value. Our approach is grounded in practical applications and measurable results, ensuring that our use of AI always serves a clear purpose."
        />
      </ItemsHolder>

      <TextPhotoSection
        title="Kickstage Responsibility"
        description="At Kickstage, we don't just adapt to the AI revolution – we lead it responsibly. By adhering to these principles, we ensure that our use of AI technologies drives genuine innovation, maintains the highest standards of quality, and delivers real value to our clients and team members alike."
        image={tempImage.responsibilityImage}
        radialTextDecoration
        radialTextDecorationColor="yellow"
        radialTextDecorationPosition="top-right"
        extraSmallPaddingBottom
      />
      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default OurWork;

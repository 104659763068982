import React from "react";
import "./ordered-item.scss";
import { Heading } from "../Heading/Heading";

export const OrderedItem = ({ order, title, description }) => {
  return (
    <div className="ordered-item">
      <div className="ordered-item__order">
        <span>{order}</span>
      </div>
      <div className="ordered-item__content">
        <Heading level="h4">{title}</Heading>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
